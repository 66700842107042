import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home'
import Login from '../views/Login/Index'
import ShopManage from '../views/Manage/Index' //连锁店铺管理
import UserInfo from "../views/Manage/UserInfo"; //根据agentId查询用户信息
import FlagshipList from "../views/Manage/FlagshipList"; //旗舰店铺
import ShopChangeList from "../views/Manage/ShopChangeList"; //店铺新增、流失情况
import Search from "../views/Manage/Search"; //查询
import Rule from "../components/Home/Rule";  //规则
import DepositDetails from "../views/Manage/DepositDetails";

Vue.use(Router)

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/login', name: 'Login', component: Login },
  { path: '/shopManage/:id', name: 'ShopManage', component: ShopManage },
  { path: '/discountCode', name: 'SuperVDiscountCode', component: () => import('../views/Manage/SuperVCode'), meta: { title: '生成优惠码' } }, //超V优惠码
  { path: '/userInfo/:id', name: 'UserInfo', component: UserInfo },
  { path: '/FlagshipList', name: 'FlagshipList', component: FlagshipList },
  { path: '/ShopChangeList/:id/:type/:time', name: 'ShopChangeList', component: ShopChangeList },
  { path: '/search', name: 'Search', component: Search },
  { path: '/halfPoster', name: 'HalfTimePoster', component: () => import('../views/Activity/SeptemberMidAutimn/UpAndSixDiscount') },//美妆升级6折
  { path: '/rule', name: 'Rule', component: Rule },
  { path: '/ruleV1', name: 'RuleV1', component: () => import('../components/Regulation/Index') },//新规则
  { path: '/refund_deposit', name: 'RefundDeposit', component: () => import('../views/Manage/RefundDeposit') },//退保证金
  { path: '/double12/mwPoster', name: 'Double12MWPoster', component: () => import('../views/Activity/DoubleTwelve/mwPoster') },//大健康海报
  { path: '/double11/mwPoster', name: 'Double11MWPoster', component: () => import('../views/Activity/DoubleEleven/mwPoster') },//双11大健康海报
  { path: '/double11/fjsPoster', name: 'Double11FJSPoster', component: () => import('../views/Activity/DoubleEleven/fjsPoster') },//双11梵洁诗海报
  { path: '/double11/xmPoster', name: 'Double11XMPoster', component: () => import('../views/Activity/DoubleEleven/xmPoster') },//双香蜜海报
  { path: '/newYear/poster', name: 'NewYearPoster', component: () => import('../views/Activity/HappyNew/Poster') },//店主新年海报
  { path: '/newYear/mw_poster', name: 'NewYearMWPoster', component: () => import('../views/Activity/HappyNew/MWPoster') },//蔓薇店主新年海报
  { path: '/newYear/mw_spring_poster', name: 'springPoster', component: () => import('../views/Activity/HappyNew/MWSpringPoster') },//蔓薇店主新年海报
  { path: '/newYear/xm_poster', name: 'TwelveXMPoster', component: () => import('../views/Activity/HappyNew/XMPoster') },//香蜜促销活动
  { path: '/signUp', name: 'SignUp_', component: () => import('../views/Activity/SignUp/SignUp') },//年会报名
  { path: '/Recharge/InformationFill', name: 'InformationFill_', component: () => import('../views/Activity/SignUp/InformationFill') },//填写年会报名资料
  { path: '/Recharge/AnnualMeetingList', name: 'AnnualMeetingList_', component: () => import('../views/Activity/SignUp/list') },
  { path: '/miSignUp', name: 'MiSignUp', component: () => import('../views/Activity/MiUserMeeting/SignUp'), meta: { title: '青海旅游报名' } },//消费者年会报名
  { path: '/miInformationFill/:type', name: 'MiInformationFill', component: () => import('../views/Activity/MiUserMeeting/InformationFill'), meta: { title: '青海旅游报名' } },//填写年会报名资料
  { path: '/miMeetingList', name: 'MiMeetingList', component: () => import('../views/Activity/MiUserMeeting/list'), meta: { title: '青海旅游报名' } },
  { path: '/Exchange', name: 'index', component: () => import('../views/Activity/Exchange/index') },
  { path: '/Recharge/fjs_poster', name: 'FJSPoster', component: () => import('../views/Activity/Recharge/FJSPoster') },//香蜜促销活动
  { path: '/Recharge/Love', name: 'Recharge', component: () => import('../views/Activity/Recharge/Love') },
  { path: '/ALiPay/:type/:orderId', name: 'ALiPay', component: () => import('../views/Pay/ALiPay') },
  { path: '/VenusIndex', name: 'VenusIndex', component: () => import('../views/Activity/Competition/VenusIndex') }, //争霸赛金星榜
  { path: '/VenusList', name: 'VenusList', component: () => import('../views/Activity/Competition/VenusList') }, //争霸赛金星榜列表
  { path: '/SilverStarIndex', name: 'SilverStarIndex', component: () => import('../views/Activity/Competition/SilverStarIndex') }, //争霸赛银星榜
  { path: '/SilverStarList', name: 'SilverStarList', component: () => import('../views/Activity/Competition/SilverStarList') }, //争霸赛银星榜列表
  { path: '/ClanList', name: 'ClanList', component: () => import('../views/Activity/Competition/ClanList') }, //争霸赛战队列表
  { path: '/PKIndex', name: 'PKIndex', component: () => import('../views/Activity/PKMatch/IndexList') }, //PK赛列表
  { path: '/FlagshipIndex/:type', name: 'FlagshipIndex', component: () => import('../views/Activity/PKMatch/FlagshipIndex') }, //旗舰店排名 1:金星 2银星
  { path: '/PKResult', name: 'PKResult', component: () => import('../views/Activity/PKMatch/MatchResult') }, //PK塞结果
  { path: '/spring/six_poster', name: 'SixFreeOne', component: () => import('../views/Activity/Spring/SixFreeOne') }, //梵洁诗春日活动海报
  { path: '/spring/xm_poster', name: 'SpringXMPoster', component: () => import('../views/Activity/Spring/XmPoster') }, //香蜜春日活动海报
  { path: '/Recharge/exchange', name: 'rechargeExchange', component: () => import('../views/Activity/Recharge/Exchange') },
  { path: '/Recharge/order_detail', name: 'OrderDetail', component: () => import('../views/Order/OrderDetail') },//订单详情
  { path: '/zero/exchange', name: 'zeroExchange', component: () => import('../views/Activity/Zero/Exchange') },
  { path: '/zero/list', name: 'zeroList', component: () => import('../views/Activity/Zero/list') },
  { path: '/zero/confirm', name: 'ZeroConfirm', component: () => import('../views/Activity/Zero/Confirm') },
  { path: '/order/ExchangeList', name: 'ExchangeList', component: () => import('../views/Order/ExchangeList') }, //订单列表--兑换订单
  { path: '/order/JigsawList', name: 'JigsawList', component: () => import('../views/PuzzleSet/JigsawList') }, //订单列表--拼图集字
  { path: '/order/logistics', name: 'OrderLogistics', component: () => import('../views/Order/Logistics') }, //订单列表--物流信息
  { path: '/order/splitiOnline/:id', name: 'SplitOrderOnline', component: () => import('../views/Order/SplitOrderOnline') }, //在线分单
  { path: '/DepositDetails', name: 'DepositDetails', component: DepositDetails },
  { path: '/exchange/poster', name: 'ExchangePoster_', component: () => import('../views/Activity/Exchange/Poster') },
  { path: '/zero/success/:id', name: 'ZeroSuccess', component: () => import('../views/Activity/Zero/success') },
  { path: '/brand/list', name: 'brandList', component: () => import('../views/Brand/ProductList') }, //品牌列表
  { path: '/puzzle/exchange/:num', name: 'PuzzleList', component: () => import('../views/PuzzleSet/Exchange'), meta: { title: '兑换旅游金' } }, //拼图集字---兑换旅游金
  { path: '/competition_list', name: 'competition_list', component: () => import('../views/Activity/Competition_/list') },
  { path: '/competition_type1', name: 'CompetitionType1', component: () => import('../views/Activity/Competition_/type1') },
  { path: '/competition_type2', name: 'competitionType2', component: () => import('../views/Activity/Competition_/type2') },
  { path: '/SelectShipment', name: 'SelectShipment', component: () => import('../views/Activity/Summer/SelectShipment') },
  { path: '/SelectSuccess/:useCount/:notUseCount/:totalCount/:orderNum/:orderId', name: 'SelectSuccess', component: () => import('../views/Activity/Summer/SelectSuccess') },
  { path: '/RefundDifference/:brandId', name: 'RefundDifference', component: () => import('../views/Manage/Bond/RefundDifference') },
  { path: '/apply1/:brandId', name: 'apply1', component: () => import('../views/Manage/Bond/apply1') },
  { path: '/apply2/:brandId', name: 'apply2', component: () => import('../views/Manage/Bond/apply2') },
  { path: '/applySuccess', name: 'applySuccess', component: () => import('../views/Manage/Bond/applySuccess') },
  { path: '/ticket/live', name: 'miLive', component: () => import('../views/ticket/miLive') },//蜜运会直播抽奖
  { path: '/fail404', name: 'Fail404', component: () => import('../views/Default/Release') },//发版停机提示
  { path: '/lottery/slot', name: 'SlotMachine', component: () => import('../views/Lottery/SlotMachine'), meta: { title: '蜜蛋豪掷' } },//抽奖活动老虎机
  { path: '/lottery/slotRecord', name: 'SlotRecordList', component: () => import('../views/Lottery/SlotRecordList'), meta: { title: '中奖记录' } },//抽奖活动老虎机抽奖记录
  { path: '/apply/green', name: 'applyGreen', component: () => import('../views/Apply/greenDiamond'), meta: { title: '申请专卖店' } },//申请专卖店
  { path: '/apply/pink', name: 'applyPink', component: () => import('../views/Apply/pinkDiamond'), meta: { title: '申请旗舰店' } },//申请旗舰店
  { path: '/apply/greenShare', name: 'applyGreenShare', component: () => import('../views/Apply/greenShare'), meta: { title: '申请专卖店' } },//申请专卖店分享
  { path: '/apply/pinkShare', name: 'applyPinkShare', component: () => import('../views/Apply/pinkShare'), meta: { title: '申请旗舰店' } },//申请旗舰店分享
  { path: '/special/list', name: 'SpecialZoneList', component: () => import('../views/Activity/SpecialZone/list'), meta: { title: '特惠专区' } },//特惠专区
  { path: '/special/detail', name: 'SpecialZoneDetail', component: () => import('../views/Activity/SpecialZone/Detail'), meta: { title: '商品详情' } },//特惠专区
  { path: '/special/confirm', name: 'SpecialZoneConfirm', component: () => import('../views/Activity/SpecialZone/Confirm'), meta: { title: '确认订单' } },//特惠专区---确认订单
  { path: '/special/success/:id', name: 'SpecialZoneSuccess', component: () => import('../views/Activity/SpecialZone/Success'), meta: { title: '下单订单' } },//特惠专区---下单成功
  { path: '/special/orderList', name: 'SpecialZoneOrderList', component: () => import('../views/Activity/SpecialZone/OrderList'), meta: { title: '订单列表' } },//特惠专区---订单列表
  // { path: '/special/orderDetail', name: 'SpecialZoneOrderDetail', component: () => import('../views/Activity/SpecialZone/orderDetail'), meta: { title: '订单详情' } },//特惠专区---订单列表
  { path: '/special/recordList', name: 'SpecialRecordList', component: () => import('../views/Activity/SpecialZone/RecordList'), meta: { title: '加购次数明细' } },//特惠专区---订单列表
  { path: '/copywriting/:id', name: 'Copywriting', component: () => import('../views/Copywriting'), meta: { title: '商品文案编辑' } },
  { path: '/accountInfo', name: 'AccountInfo', component: () => import('../views/Manage/AccountInfo'), meta: { title: '收款账号信息' } },
  { path: '/merchantIndex', name: 'MerchantIndex', component: () => import('../views/Merchant/Index'), meta: { title: '申请二级商户' } },
  { path: '/accountDetails', name: 'AccountDetails', component: () => import('../views/Merchant/AccountDetails'), meta: { title: '我的账户' } },
  { path: '/materialZone', name: 'MaterialZone', component: () => import('../views/MaterialZone/index'), meta: { title: '物料专区' } },
  { path: '/materialLimitDetail', name: 'MaterialLimitDetail', component: () => import('../views/MaterialZone/detail'), meta: { title: '查看额度明细' } },
  { path: '/online/productList', name: 'OnlineProductList', component: () => import('../views/onlineCourses/index'), meta: { title: '商品列表' } },
  { path: '/online/productDetail', name: 'OnlineProductDetail', component: () => import('../views/onlineCourses/productDetail'), meta: { title: '商品详情' } },
  { path: '/Recharge/courseConfirm', name: 'CourseConfirm', component: () => import('../views/onlineCourses/confirm'), meta: { title: '确认订单' } },
  { path: '/course/success', name: 'CourseSuccess', component: () => import('../views/onlineCourses/success'), meta: { title: '下单成功' } },
  { path: '/course/orderList', name: 'CourseOrderList', component: () => import('../views/onlineCourses/orderList'), meta: { title: '我的订单' } },
  { path: '/upgradeZone/index', name: 'JuhuiUpgradeZoneIndex', component: () => import('../views/JuhuiUpgradeZone/Index'), meta: { title: '钜惠升级专区' } },
  { path: '/upgradeZone/detail', name: 'JuhuiUpgradeZoneDetail', component: () => import('../views/JuhuiUpgradeZone/Detail'), meta: { title: '钜惠升级专区' } },
  { path: '/upgradeZoneConfirm', name: 'JuhuiUpgradeZoneConfrim', component: () => import('../views/JuhuiUpgradeZone/Confirm'), meta: { title: '确认订单' } },
  { path: '/upgradeZone/success', name: 'JuhuiUpgradeZoneSuccess', component: () => import('../views/JuhuiUpgradeZone/Success'), meta: { title: '下单成功' } },
  { path: '/generation/qrcode', name: 'GenerationQrcode', component: () => import('../views/Generation/Qrcode'), meta: { title: '梵洁诗美白祛斑系列' } },
  { path: '/retailZone/list', name: 'RetailZoneList', component: () => import('../views/Activity/RetailZone/list'), meta: { title: '兑换专区' } },//零售兑换券专区
  { path: '/retailZone/detail', name: 'RetailZoneDetail', component: () => import('../views/Activity/RetailZone/Detail'), meta: { title: '商品详情' } },//零售兑换券专区
  { path: '/retailZone/confirm', name: 'RetailZoneConfirm', component: () => import('../views/Activity/RetailZone/Confirm'), meta: { title: '确认订单' } },//零售兑换券专区---确认订单
  { path: '/retailZone/success/:id', name: 'RetailZoneSuccess', component: () => import('../views/Activity/RetailZone/Success'), meta: { title: '下单订单' } },//零售兑换券专区---下单成功
  { path: '/retailZone/orderList', name: 'RetailZoneOrderList', component: () => import('../views/Activity/RetailZone/OrderList'), meta: { title: '订单列表' } },//零售兑换券专区---订单列表
]
const router = new Router({
  mode: 'hash',
  routes,
});
router.beforeEach((to, from, next) => {
  let { title } = to.meta;
  if (!title) title = "蜜蛋网"
  document.title = title;
  next();
});
router.afterEach(() => {
  window.scrollTo(0, 0);
})

export default router;
