<!-- 菜单导航 -->
<template>
  <div>
    <div class="menu-list">
      <div class="menu-item" v-for="(item, index) in navList" :key="index" :style="'background-color:' + item.bgColor">
        <div class="subtitle">{{ item.title }}</div>
        <van-grid :border="false" :column-num="item.column">
          <template v-for="(it, idx) in item.child">
            <van-grid-item :class="{ 'special': it.imagePath }" :key="idx" @click="routerChange(it)" v-show="!it.isHide">
              <template #icon>
                <img :src="it.imagePath" v-if="it.imagePath" />
                <span :class="it.icon" :style="'color:' + it.color" v-else />
              </template>
              <template #text>
                <span class="van-grid-item__text">{{ it.text }}</span>
                <div class="label" v-if="it.label">{{ it.label }}</div>
              </template>
            </van-grid-item>
          </template>
        </van-grid>
      </div>
    </div>
    <shop-dialog :index="shopDialogIndex" v-if="showShopDialogLayer" @close="showShopDialogLayer = false" />
    <van-popup round v-model="showSuperLayer" closeable>
      <super-prompt :user-info="userInfo" />
    </van-popup>
  </div>
</template>

<script>
import ShopDialog from "./ShopDialog";
import SuperPrompt from "./SuperPrompt"
export default {
  name: '',
  props: {
    userIdentityV4: {
      type: Number,
      default: -1 //新人-1 超V 0 代理1 取消授权2
    },
    userInfo: {
      type: Object,
      default() {
        return null
      }
    }
  },
  components: { ShopDialog, SuperPrompt },
  data() {
    return {
      navList: [
        // type:1 router 2 th5 3 tuser
        {
          title: '采买',
          bgColor: '#fff5f7',
          column: 4,
          child: [
            { id: 11, text: '采购订货', isHide: false, color: '#FF99A1', type: 2, category: 2, cancel: true, url: '/Arongleweb/html/groupon/groupon_list.html', imagePath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20211224144509638.gif', },
            { id: 12, text: '买采购券', isHide: false, color: '#FF99A1', icon: 'icon-buy-coupon', type: 3, category: 1, cancel: true, url: '/rolorweb/html/recharge/buy_coupon.html' },
            { id: 9, text: '订单豪礼', isHide: false, color: '#FF99A1', icon: 'icon-gorgeous', type: 2, category: 1, cancel: true, url: '/Arongleweb/html/lucky/index.html' },
            // { id: 15, text: '兑换专区', isHide: false, color: '#FF99A1', icon: 'icon-exchange', type: 1, category: 0, cancel: true, url: '/zero/exchange' },
            { id: 17, text: '特惠专区', isHide: false, color: '#FF99A1', icon: '', type: 1, category: 3, cancel: true, url: '/special/list', imagePath: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20220401103209383.png' },

            { id: 8, text: '物料专区', isHide: false, color: '#FF99A1', icon: 'icon-materials', type: 2, category: 1, url: '/html/supplies/index.html' },
            { id: 7, text: '蜜豆商城', isHide: false, color: '#FF99A1', icon: 'icon-beans', type: 3, category: 0, cancel: true, url: '/rolorweb/html/honeyBeans/honey_beansC.html' },
            // { id: 16, text: '拼图集字', isHide: false, color: '#FF99A1', icon: 'icon-jigsaw', type: 3, category: 0, cancel: true, url: '/rolorweb/html/puzzleGame/index.html' },
            { id: 18, text: '爆单训练营', isHide: false, icon: '', type: 1, color: '#F5D15B', category: 0, cancel: true, url: '/online/productList', imagePath: "http://freesaasofficedata.oss.aliyuncs.com/mishop/20230925153152372.png" }
          ]
        },
        {
          title: '开店',
          bgColor: '#fcfaff',
          column: 2,
          child: [
            { id: 2, text: '直接/抵扣', label: '开旗舰店', isHide: false, color: '#BD9BFB', icon: 'icon-mi-shop', type: 1, category: 0, cancel: true, url: '/apply/pink' },
            // { id: 3, text: '一键注册', label: '开专卖店', isHide: false, color: '#BD9BFB', icon: 'icon-one-key', type: 3, category: 0, cancel: true, url: '/rolorweb/html/recharge/register.html' },
            // { id: 1, text: '采购订货', label: '开专卖店', isHide: false, color: '#BD9BFB', icon: 'icon-cgdh', type: 1, category: 0, cancel: true, url: '/apply/green' },
            { id: 19, text: '钜惠升级', label: "专区", icon: '', isHide: false, type: 1, color: '#F5D15B', category: 0, cancel: true, url: '/upgradeZone/index', imagePath: "http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230913142931051.gif" }
          ]
        },
        {
          title: '工具',
          bgColor: '#fffefa',
          column: 2,
          child: [

            { id: 14, text: '客服中心', icon: 'icon-service', isHide: false, type: 2, color: '#F5D15B', category: 0, cancel: true, url: '/Arongleweb/html/mycenter/service.html' },
            //  /Tool/html/index.htmlx
            // { id: 4, text: '工具箱', icon: 'icon-tool', isHide: false, type: 2, color: '#F5D15B', category: 0, cancel: true, url: '' },
            { id: 10, text: '零售管理', icon: 'icon-mi-manage', isHide: false, type: 3, color: '#F5D15B', category: 1, cancel: true, url: '/rolorweb/html/business/retail.html' },
            // { id: 18, text: '爆单训练营', icon: '', isHide: false, type: 1, color: '#F5D15B', category: 0, cancel: true, url: '/online/productList' ,imagePath:"http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230725152856457.gif"}
          ]
        }
      ],
      shopDialogIndex: -1,// 开店弹框内容索引
      showShopDialogLayer: false, // 显示开店弹窗
      showSuperLayer: false,//超V弹窗
    }
  },
  created() {
  },
  methods: {
    routerChange(item) {
      if (!item.url) return this.$toast("升级中,敬请期待!");
      const { id, category } = item;
      //userIdentityV4 新人-1 超V 0 代理1 取消授权2
      if ((this.userIdentityV4 == 2 && !item.cancel) || (id === 17 && this.userInfo.jkState === 4)) {
        //取消授权+需要授权的
        let errMsg = '您的身份暂未激活，无法进入该板块';
        if (id === 17) errMsg = '特惠专区为店主专享\n如有异常请联系客服处理！';
        this.$dialog.confirm({
          title: '提示',
          message: errMsg,
          cancelButtonText: '确认',
          confirmButtonText: '找客服'
        }).then(e =>
          window.location.href = this.baseHost + '/7999/Arongleweb/html/mycenter/service.html'
        ).catch(e => { })
        return;
      } else if ([17].indexOf(item.id) >= 0) { //新人、超V、均可进入
        return this.pageJump(item);
      } else if (this.userIdentityV4 <= 0 && !(this.userIdentityV4 === 0 && ([12, 9, 8].indexOf(item.id) >= 0))) {
        if (category === 1) {
          //新人进的是需要代理的板块
          this.shopDialogIndex = id;
          return this.showShopDialogLayer = true;
        }
        if (category === 2) {
          // if (this.userIdentityV4 == 0) return this.showSuperLayer = true;
          return this.$dialog.confirm({
            title: '提示',
            message: '您暂未开启专属店铺,如需采购请先开店噢!',
            confirmButtonText: '注册店主'
          }).then(() => {
            this.$router.push("/apply/pink")
          }).catch(() => {

          });
        }
        if (category === 3) {
          return this.$dialog.alert({ title: '提示', message: '您暂未开启专属店铺,无法进入特惠专区' })
        }
      } else if ([7].indexOf(item.id) >= 0) {
        return this.jumpIsHint(item)
      }
      this.pageJump(item);
    },
    jumpIsHint: function (item) {
      this.get('/Base/ConfigAddress/GetWebInformationList?', {
        type: 93
      }, 2).then(json => {
        if (json.code === 1) {
          let list = json.response;
          if (list.length > 0) {
            return this.$dialog.alert({ title: '提示', message: list[0].url })
          } else {
            this.pageJump(item)
          }
        }
      }, 3);
    },
    //根据店主身份跳转页面
    pageJump: function ({ type, url }) {
      if (type === 1) {
        return this.$router.push(url)
      }
      window.location.href = (type === 3 ? this.userHost : (this.baseHost + '/7999')) + url;
    }
  },
  watch: {
    userIdentityV4(newVal) {
      let item = this.navList[0].child.find(it => {
        return it.id === 8;
      })
      if (item) item.isHide = !(newVal === 0 || newVal === 1);
    }
  }
}
</script>
<style lang='scss' scoped>
.menu-list {
  padding: 11px 0 11px;
  display: flex;
  flex-wrap: wrap;

  .menu-item {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 6px;
    width: 100%;
    .subtitle {
      color: #666666;
      font-size: 16px;
      font-weight: bold;
    }

    .van-grid {
      .van-grid-item {
        /deep/.van-grid-item__content {
          padding: 16px 0;
          background-color: transparent;

          .van-grid-item__icon-wrapper {
            img {
              width: 38px;
              margin-top: -8px;
            }

            span {
              font-size: 26px;
            }
          }

          .van-grid-item__text {
            color: #999;
            font-size: 11px;
            padding-top: 6px;
          }

          .label {
            color: #999999;
            font-size: 11px;
          }
        }

        &.special {
          .van-grid-item__text {
            padding-top: 0;
            // margin-top: -3px;
            position: relative;
            z-index: 10;
          }
        }
      }
    }

    &:nth-child(2) {
      flex: 1;
      margin-right: 10px;
    }

    &:nth-child(3) {
      flex: 1;
    }
  }
}
</style>